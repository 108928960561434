import React, { useState } from "react";
import Loginimg from "../Components/assets/images/login.png";
import { Link } from "react-router-dom";
import axios from "axios";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { ValidContact, ValidEmail } from "../helpers/Validators";

const Signup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmpassword] = useState("");
  const [phone_no, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const [response, setResponse] = useState({
    success: null,
    error: null,
  });
  const navigate = useNavigate();
  const [validationMessage, setValidationMessage] = useState({
    name: null,
    email: null,
    password: null,
    confirm_password: null,
    phone_no: null,
  });

  const postRegister = async (e) => {
    e.preventDefault();
    if (name === "") {
      setValidationMessage({
        name: "Name cannot be empty",
        email: null,
        password: null,
        confirm_password: null,
        phone_no: null,
      });
      return;
    } else if (email === "") {
      setValidationMessage({
        email: "Email cannot be empty",
        name: null,
        password: null,
        confirm_password: null,
        phone_no: null,
      });
      return;
    } else if (ValidEmail(email) === false) {
      setValidationMessage({
        email: "Invalid email address",
        name: null,
        password: null,
        confirm_password: null,
        phone_no: null,
      });
      return;
    } else if (password === "") {
      setValidationMessage({
        password: "Password cannot be empty",
        name: null,
        email: null,
        confirm_password: null,
        phone_no: null,
      });
      return;
    } else if (confirm_password === "") {
      setValidationMessage({
        confirm_password: "Confirm Password cannot be empty",
        name: null,
        email: null,
        password: null,
        phone_no: null,
      });
      return;
    } else if (phone_no === "") {
      setValidationMessage({
        phone_no: "Phone number cannot be empty",
        name: null,
        email: null,
        password: null,
        confirm_password: null,
      });
      return;
    } else if (ValidContact(phone_no) === false) {
      setValidationMessage({
        phone_no: "Invalid contact number",
        name: null,
        email: null,
        password: null,
        confirm_password: null,
      });
      return;
    } else {
      setValidationMessage({
        phone_no: null,
        name: null,
        email: null,
        password: null,
        confirm_password: null,
      });
      setLoading(true);
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/user/register/send-verifycode`,
        data: {
          name: name,
          email: email,
          password: password,
          confirm_password: confirm_password,
          phone_no: phone_no,
        },
      })
        .then((res) => {
          if (res.data.status === true) {
            navigate("/signupverification", {
              state: {
                name: name,
                email: email,
                password: password,
                confirm_password: confirm_password,
                phone_no: phone_no,
              },
            });
            setResponse({
              success: "Your account has been created successfully",
              error: null,
            });
            setEmail("");
            setPassword("");
            console.log("code kkoooo", res.data);
          } else {
            setResponse({
              success: null,
              error: res.data.message,
            });
          }
        })
        .catch((error) => {
          if(error.response){
            setResponse({
              success:null,
              error:error.response.data.message
            })
            // setMessage(error.response.data.message)
  
          }
  
          else{
            setResponse({
              success:null,
              error:"Server Error"
            })
          }
        });
      setLoading(false);
    }
    setTimeout(() => {
      setResponse({
        success: null,
        error: null,
      });
    }, 5000);
  };

  const [check, setCheck] = useState(false);

  const handleCheck = (e) => {
    const checked = e.target.checked;
    if (checked) {
      setCheck(true);
    } else {
      setCheck(false);
    }
  };

  return (
    <>
      <div className="login ">
        <div className="container">
          <div className="back-heading">
            <Link to="/">
              <p> {"< "} Go to home page </p>
            </Link>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="login-image">
                <img src={Loginimg} alt="login image" />
              </div>
            </div>
            <div className=" col-lg-6 col-md-6 login-form">
              <div className="login-title">
                <h6>Register with Nepali Garden</h6>
                <p>
                  Already register?
                  <Link to="/login" className="underline-click">
                    click here to sign in
                  </Link>
                </p>
              </div>

              {response.error ? (
                <>
                  <Alert severity="error">{response.error}</Alert>
                </>
              ) : null}

              <div className="login-form ">
                <div className="input-group mt-4">
                  <label htmlFor="">Full Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Full Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onKeyPress={(event) => { if (!/[A-Z a-z]/.test(event.key)) { event.preventDefault(); } }}
                    style={
                      validationMessage.name
                        ? {
                          border: "1px solid red",
                        }
                        : null
                    }
                  />
                  <span className="input-group-text">
                    <i class="fa-solid fa-user"></i>
                  </span>
                </div>

                {validationMessage.name ? (
                  <span className="error error-message">
                    {validationMessage.name}
                  </span>
                ) : null}
                
                <div className="input-group mt-4">
                  <label htmlFor="">Email</label>
                  <input
                    type="email"
                    class="form-control"
                    placeholder="name@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={
                      validationMessage.email
                        ? {
                          border: "1px solid red",
                        }
                        : null
                    }
                  />
                  <span className="input-group-text">
                    <i class="fas fa-envelope"></i>
                  </span>
                </div>

                {validationMessage.email ? (
                  <span className="error error-message">
                    {validationMessage.email}
                  </span>
                ) : null}
                <div className="input-group mt-4">
                  <label htmlFor="">Password</label>
                  <input
                    type="password"
                    class="form-control"
                    placeholder="........"
                    value={password}
                    style={
                      validationMessage.password
                        ? {
                          border: "1px solid red",
                        }
                        : null
                    }
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span className="input-group-text">
                    <i class="fas fa-eye-slash"></i>
                  </span>
                </div>

                {validationMessage.password ? (
                  <span className="error error-message">
                    {validationMessage.password}
                  </span>
                ) : null}
                <div className="input-group mt-4">
                  <label htmlFor="">Confirm Password</label>
                  <input
                    type="password"
                    class="form-control"
                    placeholder="........"
                    value={confirm_password}
                    onChange={(e) => setConfirmpassword(e.target.value)}
                    style={
                      validationMessage.confirm_password
                        ? {
                          border: "1px solid red",
                        }
                        : null
                    }
                  />
                  <span className="input-group-text">
                    <i class="fas fa-eye-slash"></i>
                  </span>
                </div>

                {validationMessage.confirm_password ? (
                  <span className="error error-message">
                    {validationMessage.confirm_password}
                  </span>
                ) : null}
                <div className="input-group mt-4">
                  <label htmlFor="">Phone Number</label>
                  <input
                    type="tel"
                    class="form-control"
                    placeholder="Phone"
                    value={phone_no}
                    style={
                      validationMessage.phone_no
                        ? {
                          border: "1px solid red",
                        }
                        : null
                    }
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <span className="input-group-text">
                    <i class="fa-solid fa-phone"></i>
                  </span>
                </div>

                {validationMessage.phone_no ? (
                  <span className="error error-message">
                    {validationMessage.phone_no}
                  </span>
                ) : null}

                <div className="form-check mt-4">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value="1"
                    id="flexCheckDefault"
                    onClick={(e) => {
                      handleCheck(e);
                    }}
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    i agree with Nepali Garden's&nbsp;
                    <Link to="/termsandconditions" className="underline-click">
                      Terms and Condition
                    </Link>
                  </label>
                </div>

                {check ? (
                  <div className="button">
                    <LoadingButton
                      className="btn"
                      loading={loading}
                      variant="outlined"
                      onClick={postRegister}
                      disabled={false}
                    >
                      Register with Nepali Garden
                    </LoadingButton>
                  </div>
                ) : (
                  <div className="button">
                    <LoadingButton
                      className="btn"
                      loading={loading}
                      variant="outlined"
                      onClick={postRegister}
                      disabled={true}
                    >
                      Register with Nepali Garden
                    </LoadingButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Signup;
