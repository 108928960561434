import React from "react";
import { NavLink } from "react-router-dom";

const AddressInfoForm = () => {
  return (
    <div className="edit-profile-main">
      <h3>Address Information Form</h3>
      <div className="edit-profile-content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="name-field">
                <label htmlFor="">Full Name</label>
                <br />
                <input type="text" placeholder="Enter your full name" />
              </div>
              <div className="name-field">
                <label htmlFor="">State</label>
                <br />
                <select value="male">
                  <option name="" id="">
                    State 1
                  </option>
                  <option name="" id="">
                    State 2
                  </option>
                </select>
              </div>
              <div className="name-field">
                <label htmlFor="">City Address</label>
                <br />
                <input type="text" placeholder="Enter your city address" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="name-field">
                <label htmlFor="">Phone Number</label>
                <br />
                <input type="text" placeholder="Enter your phone number" />
              </div>
              <div className="name-field">
                <label htmlFor="">Post Code</label>
                <br />
                <input type="text" placeholder="Enter your postal code" />
              </div>
              <div className="name-field">
                <label htmlFor="">Street Address</label>
                <br />
                <input type="text" placeholder="Enter your street address" />
              </div>
            </div>
          </div>
          <div className="profile-changes-btn">
                    <button>Save Address</button>
                    </div>
        </div>
      </div>
    </div>
  );
};

export default AddressInfoForm;
