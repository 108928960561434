import React, { useEffect } from "react";
import Parse from "html-react-parser";
import { useState } from "react";
import axios from "axios";
import AboutLoader from "./Loader";
const About = () => {
  const [about, setAbout] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/about-page/get`,
    })
      .then((res) => {
        setAbout(res.data.data[0]);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, []);

  console.log("About", about);
  return (
    <>
      <div className="about_page container mb-3">
        <div className="contact_heading">
          <h3>About us</h3>
        </div>

        {loading ? (
          <AboutLoader />
        ) : (
          <>
            {about?.img ? (
              <div className="about_image">
                <img src={about?.image} alt="about image" />
              </div>
            ) : null}

            <div className="about_desc">
              {Parse(about?.description ? about.description : "")}
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default About;
