import React, { useState, useEffect, useRef } from "react";
import Loginimg from "../Components/assets/images/login.png";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { Alert, LoadingButton } from "@mui/lab";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showSuccessSnackbar } from "../Services/Action/snackbarActions";
import { useSnackbar } from "notistack";
import { ValidEmail } from "../helpers/Validators";

const Login = (props) => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [validationMessage, setValidationMessage] = useState({
    email: null,
    password: null,
  });

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({
    success: null,
    error: null,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    setResponse({
      error: null,
      success: location.state?.message,
    });
  }, [location]);

  const postLogin = async (e) => {
    
    e.preventDefault();
    if (email === "") {
      setValidationMessage({
        email: "Email Cannot be empty",
        password: null,
      });

      return;
    } else if (ValidEmail(email) === false) {
      setValidationMessage({
        email: "Invalid Email",
        password: null,
      });

      return;
    } else if (password === "") {
      setValidationMessage({
        email: null,
        password: "Password Cannot be empty",
      });
      return;

    } 
    
    else {
      setLoading(true);
      setValidationMessage({ email: null, password: null });
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/user/login`,
        data: {
          email: email,
          password: password,
        },
      })
        .then((res) => {
          if (res.data.status === true) {
            props.setUser(res.data.data);
            console.log("I am called");
            setResponse({
              success: "Login successful",
              error: null,
            });
            enqueueSnackbar(`Welcome,${res.data.data.user.name}`, {
              variant: "success",
            });
            navigate("/");
            setEmail("");
            setPassword("");
          } else {
            setResponse({
              success: null,
              error: res.data.message,
            });
            enqueueSnackbar(res.data.message, {
              variant: "error",
            });
          }
        })
        .catch((error) => {
          if(error.response){
            enqueueSnackbar(error.response.data.message, {
              variant: "error",
            });
          }
          else{
            enqueueSnackbar("Server Error", {
              variant: "error",
            });
          }
       
        });
      setLoading(false);
    }
    setTimeout(() => {
      setResponse({
        success: null,
        error: null,
      });
    }, 5000);
  };

  return (
    <>
      <div className="login">
        <div className="container">
          <div className="back-heading">
            <Link to="/">
              <p> {"< "} Go to home page </p>
            </Link>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="login-image">
                <img src={Loginimg} alt="login image" />
              </div>
            </div>
            <div className=" col-lg-6 col-md-6 login-form">
              <div className="login-title">
                <h6>Sign in to your account</h6>
                <p>
                  Haven't register yet?
                  <Link to="/signup" className="underline-click">
                    click here to sign up
                  </Link>
                </p>
              </div>

              {/* {response.error ? (
                <>
                  <Alert severity="error" className="my-4">
                    {response.error}
                  </Alert>
                </>
              ) : null}
              {response.success ? (
                <>
                  <Alert severity="success" className="my-4">
                    {response.success}
                  </Alert>
                </>
              ) : null} */}
              <form>
                <div className="login-form " type="submit">
                  <div className="input-group mt-4">
                    <label htmlFor="">Email</label>

                    <input
                      type="email"
                      className="form-control"
                      placeholder="name@example.com"
                      value={email}
                      style={
                        validationMessage.email
                          ? {
                              border: "1px solid red",
                            }
                          : null
                      }
                      onChange={(e) => setEmail(e.target.value)}
                    />

                    <span className="input-group-text">
                      <i class="fas fa-envelope"></i>
                    </span>
                  </div>
                  {validationMessage.email ? (
                    <span className="error error-message">
                      {validationMessage.email}
                    </span>
                  ) : null}

                  <div className="input-group mt-4">
                    <label htmlFor="">Password</label>
                    <input
                      type="password"
                      class="form-control"
                      placeholder="........"
                      value={password}
                      style={
                        validationMessage.password
                          ? {
                              border: "1px solid red",
                            }
                          : null
                      }
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span className="input-group-text">
                      <i class="fas fa-eye-slash"></i>
                    </span>
                  </div>

                  {validationMessage.password ? (
                    <span className="error error-message">
                      {validationMessage.password}
                    </span>
                  ) : null}

                  <div class="form-check mt-4">
                    <label class="form-check-label" for="flexCheckDefault">
                      Forgot your password?
                      <Link to="/passwordreset" className="underline-click">
                        click here to reset
                      </Link>
                    </label>
                  </div>

                  <div className="button">
                    <LoadingButton
                      className="btn"
                      type="submit"
                      loading={loading}
                      variant="outlined"
                      onClick={postLogin}
                    >
                      Take me to my account
                    </LoadingButton>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
