import React from "react";
import LeftBarSide from "./LeftBarSide";
import ProfileRouting from "./ProfileRouting/ProfileRouting";

const Profile = (props) => {

  return ( 
    <div className="profile-main">
      <div className="container">
        <div className="profile-content">
          <div className="row">
            <div className="col-lg-3 mb-4">
              <LeftBarSide />
            </div>
            <div className="col-lg-9">
              <ProfileRouting user={props.user} />
            </div>
          </div>
            
        </div>
      </div>
    </div>
  );
};

export default Profile;
