import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import Homerouting from "./HomeRouting/Homerouting";
import Footer from "./Components/Footer/Footer";
import { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import SuccessSnackbar from "./Components/BodyComponents/snackbar/snackbar";
import { SnackbarProvider } from "notistack";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";

const theme = createTheme({
  palette: {
    primary: {
      main: "#B6101D",
    },
  },
});
function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    let data = localStorage.getItem("user");
    if (data) {
      setUser(JSON.parse(data));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <SuccessSnackbar />
          <Navbar user={user} setUser={setUser} />
          <Homerouting user={user} setUser={setUser} />
          <Footer />
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
