import React, { useState, useEffect } from "react";
import list from "./list";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import Skeletoncard from "../../Components/BodyComponents/skeletoncard/skeletoncard";
import { getAllByDisplayValue } from "@testing-library/react";
import ZoomImage from "../../Components/Helper/ZoomImage";
import parse from "html-react-parser";
import { useSnackbar } from "notistack";
import { addToCart } from "../../Services/Action/Action";
import { addToWishlist } from "../../Services/Action/WishlistAction";
import { useDispatch } from "react-redux";
import ProductCard from "../../Components/card/ProductCard";

const Detailpage = () => {
  const params = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [products, setProducts] = useState({});
  const [loading, setLoading] = useState(false);
  const [similarProducts, setSimilarProducts] = useState([]);

  console.log(products);

  const handler = (e, get) => {
    console.log("Get is", get);
    dispatch(addToCart(get, count));
    enqueueSnackbar("Item added to cart", {
      variant: "success",
    });
  };
  const handlerWish = (e, get) => {
    e.preventDefault();
    dispatch(addToWishlist(get));
    enqueueSnackbar("Item added to wishlist", {
      variant: "success",
    });
    console.log(get.price);
  };

  useEffect(() => {
    if (params.slug) {
      setLoading(true);
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/product/get/${params.slug}`,
      })
        .then((res) => {
          console.log(res.data.data.product);
          let response = res.data.data.product;
          setProducts(response);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err, "error message");
          setLoading(false);
        });

      // Similar products
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/product/getSimilarProducts/${params.slug}`,
      })
        .then((res) => {
          // console.log(res.data.data.product);
          let response = res.data.data.similarProducts;
          setSimilarProducts(response);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err, "error message");
          setLoading(false);
        });
    }
  }, [params.slug]);

  const [count, setCount] = useState(0);
  const [detail, setDetail] = useState([]);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/products/${params.id}`,
    })
      .then((res) => {
        console.log(res.data);
        let response = res.data;
        setDetail(response);
      })
      .catch((err) => console.log(err));
  }, []);

  const minus = () => {
    setCount(count - 1);
  };
  const add = () => {
    setCount(count + 1);
  };

  return (
    <div className="detail-main">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="detail-img">
              <ZoomImage
                image={
                  products.image
                    ? products.image
                    : "https://www.gamudacove.com.my/media/img/default-img.jpg"
                }
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="detail-content">
              <div className="detail-content-head">
                <h3>{products?.name}</h3>
                <h4>
                  {/* Rs. 12,000/- <del>Rs. 14,000/-</del> */}${products?.price}
                </h4>
              </div>
              <div className="detail-detail">
                <div className="row">
                  <div className="col-md-6  col-6">
                    <div className="detail-detail-content">
                      {/* {parse(products?.detail ? products.detail : "")} */}
                      <p>Unit in gram :</p>
                      <p>Pack per carton :</p>
                      <p>Total weight of carton in gram :</p>
                      {/* <p>Carton size :</p> */}
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div className="detail-detail-content units">
                      <p>{products?.unit_in_gram}</p>
                      <p>{products?.pack_per_carton}</p>
                      <p>{products?.total_weight}</p>
                      {/* <p>{products?.size}</p> */}
                    </div>
                  </div>
                  <div className="col-md-6 mt-2" style={{ display: "flex" }}>
                    <div>
                      <p>Quantity:</p>
                    </div>
                    <div className="detail-count collect mb-3">
                      <button onClick={minus}>-</button>{" "}
                      <input
                        value={count}
                        onChange={(e) => setCount(parseInt(e.target.value))}
                      />{" "}
                      <button onClick={add}>+</button>
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div
                      className="cart-btn collect"
                      onClick={(e) => handler(e, products)}
                    >
                      <p>
                        {" "}
                        <span>
                          <i class="fas fa-shopping-cart"></i>
                        </span>{" "}
                        ADD TO CART
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="detail-text-main">
          <div className="detail-text">
            <h4>Description</h4>
            <p>{parse(products.detail ? products.detail : "")}</p>
          </div>

          <div className="detail-text">
            <h4>Ingredients</h4>
            <p> {parse(products.ingredeints ? products.ingredeints : "")}</p>
          </div>
        </div>
        {similarProducts.length > 0 ? (
          <div className="other-main">
            <h3>View other products</h3>
            <div className="row">
              {similarProducts.map((get, keys) => {
                return (
                  <div className="col-md-3" key={get.id}>
                    <ProductCard data={get} />
                    {/* <div className="cart-main">
                      <div className="feature-img">
                        <NavLink to={`/${get.category.slug}/${get.slug}`}>
                          <img src={get.image} alt="featureproducts" />
                        </NavLink>
                        <p onClick={(e) => handler(e, get)}>
                          {" "}
                          <i class="fas fa-shopping-cart"></i>
                        </p>

                      </div>
                      <div>
                        <p className="cart-content-name">{get.name}</p>
                        <p className="cart-content-price">${get.price} </p>
                      </div>
                    </div> */}
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Detailpage;
