import React from "react";
import StripeContainer from "./StripeContainer";

const Payment = ({ paymentMethod, setPaymentMethod, order }) => {
  return (
    <>
      <div className="payment-method">Select Payment Method</div>

      <div className="row">
        {/* <div className="col-md-3">
          <div
            className={
              paymentMethod === "cod"
                ? "payment-section payment-active"
                : "payment-section"
            }
            onClick={() => setPaymentMethod("cod")}
          >
            <div>
              <img
                src="https://cdn-icons-png.flaticon.com/512/1554/1554401.png"
                alt="images"
                className=""
              />
            </div>
            <div className="payment-title">Cash On Delivery</div>
            <div className="payment-sub-title">Cash On Delivery</div>
          </div>
        </div> */}

        {/* Stripe  */}
        <div className="col-md-3">
          <div
            className={
              paymentMethod === "card"
                ? "payment-section payment-active"
                : "payment-section"
            }
            onClick={() => setPaymentMethod("card")}
          >
            <div>
              <img
                src="https://www.kindpng.com/picc/m/399-3995736_credit-card-payment-options-visa-mastercard-discover-logos.png"
                alt="images"
                className="img-fluid"
              />
            </div>
            <div className="payment-title">Credit/debit Card</div>
            {/* <div className="payment-sub-title">Credit/debit Card</div> */}
          </div>
        </div>

        {/* <div className="col-md-3">
          <div
            className={
              paymentMethod === "esewa"
                ? "payment-section payment-active"
                : "payment-section"
            }
            onClick={() => setPaymentMethod("esewa")}
          >
            <img
              src="https://timeandupdate.com/wp-content/uploads/2019/10/esewa-logo-e1417844536234.png"
              alt="images"
              className=" "
            />

            <div className="payment-title">E-Sewa</div>
            <div className="payment-sub-title">E-Sewa</div>
          </div>
        </div> */}

        {/* <div className="col-md-3">
          <div
            className={
              paymentMethod === "paypal"
                ? "payment-section payment-active"
                : "payment-section"
            }
            onClick={() => setPaymentMethod("paypal")}
          >
            <div>
              <img
                src="https://logos-world.net/wp-content/uploads/2020/05/PayPal-Logo.png"
                alt="images"
                className=""
              />
            </div>
            <div className="payment-title">Paypal</div>
            <div className="payment-sub-title">Paypal</div>
          </div>
        </div> */}

        <div className="col-md-12 mt-3">
          {paymentMethod === "card" && <StripeContainer order={order} />}
        </div>
      </div>
    </>
  );
};

export default Payment;
