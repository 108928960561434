import React, { useState, useEffect } from "react";
import Parse from "html-react-parser";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import axios from "axios";
const Learnabout = () => {
  const [about, setAbout] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/about-page/get`,
    })
      .then((res) => {
        setAbout(res.data.data[0]);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, []);
  return (
    <div className="learnabout-main">
      <div className="container">
        <div className="main-topic">
          <p>
            Learn About <span>Nepali Garden</span>
          </p>
        </div>
        {loading ? (
          <div className="my-3">
            <Stack spacing={1}>
              <Skeleton variant="text" width={"100%"} />
              <Skeleton variant="text" width={"100%"} />
              <Skeleton variant="text" width={"100%"} />
            </Stack>
          </div>
        ) : (
          <div className="learnabout-content">
            <p>{Parse(about?.description ? about.description : "")}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Learnabout;
