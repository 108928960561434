import { useDispatch, useSelector } from "react-redux";
import { clearSnackbar } from "../../../Services/Action/snackbarActions";
import React, { useEffect } from "react";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useSnackbar } from "notistack";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SuccessSnackbar() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { successSnackbarMessage, successSnackbarOpen, reload } = useSelector(
    (state) => state.ui
  );

  function handleClose() {
    dispatch(clearSnackbar());
  }
  console.log(successSnackbarOpen);

  return (
    <Snackbar
      open={successSnackbarOpen}
      autoHideDuration={4000}
      onClose={handleClose}
      aria-describedby="client-snackbar"
    >
      <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
        {successSnackbarMessage}
      </Alert>
    </Snackbar>
  );
}
