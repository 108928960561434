import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
const Resetpassword = ({ email, setEmail }) => {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    navigate("/codeverification");
  };
  const handleShow = () => setShow(true);

  const [response, setResponse] = useState({
    success: null,
    error: null,
  });

  const handleSuccess = () => {
    setOpen(true);
  };

  const postReset = async (e) => {
    e.preventDefault();
    if (email === "") {
      setResponse({
        success: null,
        error: "Please enter your email",
      });
    } else {
      setLoading(true);
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/user/sendVerificationCode`,
        data: {
          email: email,
        },
      })
        .then((res) => {
          console.log(res.data);
          if (res.data.status === true) {
            handleSuccess();
            setResponse({
              success: "Password Reset Email Has Been Sent!",
              error: null,
            });
            handleShow();
          } else {
            setResponse({
              success: null,
              error: "Invalid Credentials",
            });
          }
        })
        .catch((error) => {
          if (error.response){
            setResponse({
              success: null,
              error: error.response.data.message,
            });
          }
        });
      setLoading(false);
    }
    setTimeout(() => {
      setResponse({
        success: null,
        error: null,
      });
    }, 5000);
  };

  return (
    <>
      <div className="login">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              {/* <div className="row">
                <div className="col-md-4">
                  <div>
                    <NavLink to="/login" className="go-to">
                      {" "}
                      {"< "} Go to Login Page
                    </NavLink>
                  </div>
                </div>
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  <div>
                    <NavLink to="/" className="go-to">
                      {" "}
                      Go to home page {"> "}
                    </NavLink>
                  </div>
                </div>
              </div> */}
              <div className="register-main">
                <div className="login-title">
                  <h6>Reset Password</h6>
                  <p>
                    Enter your email below and we will send you the reset link
                  </p>
                </div>
                {response.error ? (
                  <>
                    <Alert severity="error">{response.error}</Alert>
                  </>
                ) : null}
                <div className="login-form">
                  <div className="input-group mb-3 mt-4">
                    <label htmlFor="">Email</label>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="name@example.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <span className="input-group-text">
                      <i class="fas fa-envelope"></i>
                    </span>
                  </div>
                  <div className="button">
                    <LoadingButton
                      className="btn"
                      loading={loading}
                      variant="outlined"
                      onClick={postReset}
                    >
                      Send me the reset link
                    </LoadingButton>
                  </div>
                </div>
              </div>
            </div>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header>
                <NavLink to="/codeverification">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </NavLink>

                <div className="container">
                  <div className="reset-verification-modal">
                    <div className="tick">
                      <p>
                        <i className="fa-solid fa-check"></i>
                      </p>
                    </div>
                    <div className="reset-topic">
                      <h3>Reset link Sent</h3>
                      <p>
                        Please check your email for the reset link and follow
                        the procedure
                      </p>
                    </div>
                  </div>
                </div>
              </Modal.Header>
            </Modal>
            <div className="col-md-7">
              <div className="register-image">
                <img src="images/register/reset.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Resetpassword;
