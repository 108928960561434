import React from 'react'

const Voucher = () => {
  return (
    <div className='edit-profile-main'>
        <h3>Voucher</h3>
        <div className="edit-profile-content">
                <div className="container">
                    <div className='name-field'>
                        <p>No Voucher Yet</p>
                    </div>
                </div>
        </div>
    </div>
  )
}

export default Voucher