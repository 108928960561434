import React, { useState } from "react";
import Loginimg from "../../Components/assets/images/login.png";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Alert, LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

const ConfirmNewPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [response, setResponse] = useState({
    success: null,
    error: null,
  });
  const changePassword = async (e) => {
    e.preventDefault();

    if (password === "" || rePassword === "") {
      setResponse({
        success: null,
        error: "Empty Field Found",
      });
    } else if (password !== rePassword) {
      setResponse({
        success: null,
        error: "Password doesnot match",
      });
    } else {
      setLoading(true);
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/user/forgotPassword/changepassword`,
        data: {
          email: props.email,
          newpassword: password,
          confirmpassword: rePassword,
        },
      })
        .then((res) => {
          if (res.data.status) {
            enqueueSnackbar(
              "Your password has been changed successfully. Login to continue.",
              {
                variant: "success",
              }
            );
            navigate("/login");
          } else {
            setResponse({
              success: null,
              error: "Failed to change password. Try again later",
            });
          }
        })
        .catch((e) => setResponse({ success: null, error: e.message }));
      setLoading(false);
    }
  };

  return (
    <div className="login">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="login-image">
              <img src={Loginimg} alt="login image" />
            </div>
          </div>

          <div className=" col-lg-6 col-md-6 login-form">
            <div className="login-title">
              <h6>Create New Password</h6>
              <p>
                Already register?
                <Link to="/login" className="underline-click">
                  click here to sign in
                </Link>
              </p>
            </div>
            <div className="code-verify">
              {response.error ? (
                <>
                  <Alert severity="error">{response.error}</Alert>
                </>
              ) : null}
              <div className="login-form ">
                <label htmlFor="">New Password</label>
                <div className="input-group mb-3 mt-4">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="New password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <label htmlFor="">Confirm New Password</label>
                <div className="input-group mb-3 mt-4">
                  <br />
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Confirm new password"
                    value={rePassword}
                    onChange={(e) => setRePassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="button">
                <LoadingButton
                  loading={loading}
                  onClick={changePassword}
                  className="btn "
                >
                  CONFIRM YOUR PASSWORD
                </LoadingButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmNewPassword;
