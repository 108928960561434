import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addQuantity, removeToCart } from "../../Services/Action/Action";
import { subQuantity } from "../../Services/Action/Action";
import { Link } from "react-router-dom";

const Cart = ({ user }) => {
  const getCarddata = useSelector((state) => state.cardItems);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (getCarddata) {
      if (getCarddata.cardData) {
        let res = 0;
        for (let i = 0; i < getCarddata.cardData.length; i++) {
          res =
            res +
            getCarddata.cardData[i].quantity *
              parseInt(getCarddata.cardData[i].price);
        }
        setTotal(res);
      }
    }
  }, [getCarddata]);

  const dispatch = useDispatch();

  const removeHandler = (e, get) => {
    e.preventDefault();
    dispatch(removeToCart(get));
  };

  const addHandler = (e, get) => {
    e.preventDefault();
    dispatch(addQuantity(get));
  };
  const minusHandler = (e, get) => {
    e.preventDefault();
    dispatch(subQuantity(get));
  };

  if (getCarddata?.cardData?.length > 0) {
    return (
      <div className="cart-main">
        <div className="container">
          <div className="main-topic">
            <p>Cart</p>
          </div>
          <hr />
          <form className="cart-form">
            <table className="cart-table">
              <thead>
                <tr>
                  <th className="remove"></th>
                  <th className="product-img">PRODUCT IMAGE</th>
                  <th className="product-name">PRODUCT NAME</th>
                  <th className="unitprice">UNIT PRICE</th>
                  <th className="quantity">QUANTITY</th>
                  <th className="price">AMOUNT</th>
                </tr>
              </thead>
              <tbody>
                {getCarddata.cardData
                  ? getCarddata.cardData.map((get, keys) => {
                      return (
                        <>
                          <tr>
                            <td className="remove">
                              <i
                                onClick={(e) => removeHandler(e, get)}
                                class="fa-solid fa-xmark"
                              ></i>
                            </td>
                            <td className="product-img">
                              <img src={get.image} alt="cart photoo" />
                            </td>
                            <td className="product-name">
                              <p> {get.name} </p>
                            </td>
                            <td className="unitprice">
                              <p>$ {get.price}</p>
                            </td>

                            <td className="quantity">
                              <div className="detail-count collect">
                                <button onClick={(e) => minusHandler(e, get)}>
                                  -
                                </button>{" "}
                                <input value={get.quantity} />{" "}
                                <button onClick={(e) => addHandler(e, get)}>
                                  +
                                </button>
                              </div>
                            </td>
                            <td className="price">
                              <p>$ {get.price * get.quantity} </p>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </form>
          <div className="subtotal-main">
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <div className="subtotal">
                  <div className="row">
                    <div className="col-md-4 col-6 subtotal-mob">Subtotal</div>
                    <div className="col-md-4 col-0"></div>
                    <div className="col-md-4 col-6 ">$ {total} /-</div>
                  </div>
                  {/* <div className="row">
                    <div className="col-md-4 subtotal-mob">Shipping Fee</div>
                    <div className="col-md-4"></div>
                    <div className="col-md-4">$ 200 /-</div>
                  </div> */}
                  <hr />
                  <div className="row">
                    <div className="col-md-4 total-cart">TOTAL</div>
                    <div className="col-md-4"></div>
                    <div className="col-md-4 total-cart">$ {total}</div>
                  </div>
                  <div className="checkout">
                    <Link to={user ? "/checkout" : "/login"} className="button">
                      <button className="btn">Check Out</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="cart-main">
          <div className="container">
            <div className="main-topic button">
              <p>Cart is empty</p>
              <Link to="/" className="button btn-shopping">
                <button className="btn">Go Shopping</button>
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Cart;
