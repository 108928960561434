import React, { useEffect, useState } from "react";
import Logo from "../assets/images/logo.png";
import Payment1 from "../assets/images/applepay.png";
import Payment2 from "../assets/images/discover.png";
import Payment3 from "../assets/images/mastercard.png";
import Payment4 from "../assets/images/paypal.png";
import Payment5 from "../assets/images/visa.png";
import Payment6 from "../assets/images/zelle.png";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "notistack";
import { ValidEmail } from "../../helpers/Validators";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [contactInfo, setContactInfo] = useState(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const subscribe = () => {
    if (email === "") {
      return;
    }

    if (ValidEmail(email) === false) {
      enqueueSnackbar("Invalid Email", {
        variant: "error",
      });

      return;
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/enquiry/store`,
      data: {
        email: email,
      },
    })
      .then((res) => {
        if (res.data.status) {
          enqueueSnackbar("Thank you for subscribing", {
            variant: "success",
          });

          window.scroll(0, 0);
          setEmail("");
        } else {
          enqueueSnackbar("Failed to subscribe. Try again later.", {
            variant: "error",
          });
        }
      })
      .catch((e) => {
        enqueueSnackbar("Failed to subscribe. Try again later.", {
          variant: "error",
        });
      });
  };

  // Fetch contact information
  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/contact-page/get`,
    })
      .then((res) => setContactInfo(res.data.data[0] ? res.data.data[0] : null))
      .catch((e) => console.log(e.message));
  }, []);

  return (
    <>
      <div className="footer ">
        <div className="top_footer container">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <div className="first_section">
                <div>
                  <img src={Logo} alt="footer logo" width="120" />
                </div>
                <ul>
                  <li>{contactInfo?.address}</li>
                  {/* <li>richmond hil</li> */}
                  {/* <li>ny 11418</li> */}
                  <li>{contactInfo?.phone}</li>
                  <li>{contactInfo?.email}</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 d-md-none d-lg-block">
              <div className="first_section">
                <ul>
                  <li className="first_section_terms">
                    <NavLink to="/about" className="linked">
                      {" "}
                      about us
                    </NavLink>
                  </li>
                  {/* <li className="first_section_terms">faqs</li> */}
                  <li className="first_section_terms">
                    <NavLink to="/contact" className="linked">
                      {" "}
                      contact us
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 d-md-none d-lg-block">
              <div className="first_section">
                <ul>
                  <li className="first_section_terms">
                    <NavLink to="/termsandconditions" className="linked">
                      {" "}
                      Terms & Conditions
                    </NavLink>
                  </li>
                  {/* <li className="first_section_terms">
                    <NavLink to="#" className="linked">
                      {" "}
                      shipped items
                    </NavLink>
                  </li> */}
                  {/* <li className="first_section_terms">location</li>
                  <li className="first_section_terms">discounts</li> */}
                  <li className="first_section_terms">
                    <NavLink to="/return-policy" className="linked">
                      {" "}
                      return policy
                    </NavLink>
                  </li>
                  {/* <li className="first_section_terms">location</li> */}
                  {/* <li className="first_section_terms">discounts</li> */}
                  <li className="first_section_terms">
                    <NavLink to="/privacy-policy" className="linked">
                      {" "}
                      privacy policy
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4">
              <div className="first_section">
                <div className="social_icon">
                  <a href={contactInfo?.youtube} target="_blank">
                    <i class="fa-brands fa-youtube"></i>
                  </a>

                  <a href={contactInfo?.twitter} target="_blank">
                    <i class="fa-brands fa-twitter"></i>
                  </a>

                  <a href={contactInfo?.facebook} target="_blank">
                    <i class="fa-brands fa-facebook-f"></i>
                  </a>

                  <a href={contactInfo?.instagram} target="_blank">
                    <i class="fa-brands fa-instagram"></i>
                  </a>

                  {/* <i class="fa-brands fa-twitter"></i>
                  <i class="fa-brands fa-facebook-f"></i>
                  <i class="fa-brands fa-instagram"></i> */}
                </div>
                <ul>
                  <li>sign up for our newsletter</li>
                  <li className="li-footer">
                    <input
                      type="email"
                      placeholder="Your Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <br />
                    <button
                      type="submit"
                      onClick={subscribe}
                      className=" footer-submit "
                    >
                      submit
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 ">
              <div className="map">
                {/* {contactInfo?.google_map} */}
                <iframe
                  src={contactInfo?.google_map}
                  width="100%"
                  height="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="copyright_text">
            <h6>
              Copyright &copy; {new Date().getFullYear()}, Nepali Garden |
              Developed by: Creatu Developers
            </h6>
          </div>
        </div>
        <div className="payment">
          <div className="payment_type">
            <img src={Payment1} alt="payment method" />
          </div>
          <div className="payment_type">
            <img src={Payment2} alt="payment method" />
          </div>
          <div className="payment_type">
            <img src={Payment3} alt="payment method" />
          </div>
          <div className="payment_type">
            <img src={Payment4} alt="payment method" />
          </div>
          <div className="payment_type">
            <img src={Payment5} alt="payment method" />
          </div>
          <div className="payment_type">
            <img src={Payment6} alt="payment method" />
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
