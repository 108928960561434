import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function Skeletoncard() {
  return (
    <div className="row">
      <div className="col-md-2">
        <Stack spacing={1}>
          <Skeleton variant="rectangular" width={"100%"} height={118} />

          <Skeleton variant="text" width={"80%"} />
          <Skeleton variant="text" width={"80%"} />
          <Skeleton variant="text" width={80} />
        </Stack>
      </div>

      <div className="col-md-2">
        <Stack spacing={1}>
          <Skeleton variant="rectangular" width={"100%"} height={118} />

          <Skeleton variant="text" width={"80%"} />
          <Skeleton variant="text" width={"80%"} />
          <Skeleton variant="text" width={80} />
        </Stack>
      </div>
      <div className="col-md-2">
        <Stack spacing={1}>
          <Skeleton variant="rectangular" width={"100%"} height={118} />

          <Skeleton variant="text" width={"80%"} />
          <Skeleton variant="text" width={"80%"} />
          <Skeleton variant="text" width={80} />
        </Stack>
      </div>
      <div className="col-md-2">
        <Stack spacing={1}>
          <Skeleton variant="rectangular" width={"100%"} height={118} />

          <Skeleton variant="text" width={"80%"} />
          <Skeleton variant="text" width={"80%"} />
          <Skeleton variant="text" width={80} />
        </Stack>
      </div>
      <div className="col-md-2">
        <Stack spacing={1}>
          <Skeleton variant="rectangular" width={"100%"} height={118} />

          <Skeleton variant="text" width={"80%"} />
          <Skeleton variant="text" width={"80%"} />
          <Skeleton variant="text" width={80} />
        </Stack>
      </div>
      <div className="col-md-2">
        <Stack spacing={1}>
          <Skeleton variant="rectangular" width={"100%"} height={118} />

          <Skeleton variant="text" width={"80%"} />
          <Skeleton variant="text" width={"80%"} />
          <Skeleton variant="text" width={80} />
        </Stack>
      </div>
    </div>
  );
}
