import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { addToCart } from "../../Services/Action/Action";

function ProductCard({ data }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const handler = (e, get) => {
    dispatch(addToCart(get));
    enqueueSnackbar("Item added to cart", {
      variant: "success",
    });
  };
  return (
    <>
      <div className="cart-main">
        <div className="feature-img">
          <NavLink to={`/product/${data.slug}`}>
            <img src={data.image} alt="featureproducts" />
          </NavLink>
        </div>
        <div className="feature-data">
          <NavLink to={`/product/${data.slug}`}>
            <p className="cart-content-name">{data.name}</p>
          </NavLink>
          <p className="cart-content-price">$ {data.price}</p>
        </div>
        <div className="cart_icons">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="cart-hand d-grid">
                <button
                  type="button"
                  className="btn btn-sm btn-block"
                  onClick={(e) => handler(e, data)}
                >
                  {" "}
                  <i class="fas fa-shopping-cart pe-1"></i>
                  ADD TO CART
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductCard;
