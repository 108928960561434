export function ValidEmail(email) {
  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (email.match(validRegex)) {
    return true;
  } else {
    return false;
  }
}

export function ValidContact(phone) {
  var phoneno = /^\d{10}$/;
  let contact=phone+""
  if (contact.match(phoneno)) {
    return true;
  } else {
    return false;
  }
}
