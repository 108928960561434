import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Parse from "html-react-parser";
import axios from "axios";
import { Alert, LoadingButton } from "@mui/lab";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { useSnackbar } from "notistack";
import { ValidEmail } from "../helpers/Validators";

export const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [contactLoading, setContactLoading] = useState(false);
  const [response, setResponse] = useState({
    success: null,
    error: null,
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [validationMessage, setValidationMessage] = useState({
    name: null,
    email: null,
    message: null,
  });

  const [contact, setContact] = useState([]);
  useEffect(() => {
    setContactLoading(true);
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/contact-page/get`,
    })
      .then((res) => {
        setContact(res.data.data);
        setContactLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setContactLoading(false);
      });
  }, []);




  const postSubmit = async (e) => {
    e.preventDefault();

    if (name === "") {
      setValidationMessage({
        name: 'Name cannot be empty',
        email: null,
        message: null,
      });
      return;
    }

    else if (email === "") {
      setValidationMessage({
        name:null,
        email: "Email Cannot be empty",
        message: null,
      });
      return;
    }
    
    else if (ValidEmail(email) === false) {
      setValidationMessage({
        name:null,
        email: "Invalid Email",
        message: null,
      });
      return;
    }

    if (message === "") {
      setValidationMessage({
        name:null,
        email:null,
        message:"Message cannot be empty",
      });
      return;
    } else {
      setValidationMessage({
        name: null,
        email: null,
        message: null,
      });
    
    // if (name === "" || email === "" || message === "") {
    //   setResponse({
    //     success: null,
    //     error: "Empty field detected",
    //   });
    // }
    
    
      setLoading(true);
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/contactMessage/store`,
        data: {
          name: name,
          email: email,
          message: message,
        },
      })
        .then((res) => {
          if (res.data.status === true) {
            enqueueSnackbar(
              "Thank You for contacting. We will get back to you soon .",
              {
                variant: "success",
              }
            );
            setName("");
            setEmail("");
            setMessage("");
          } else {
            setResponse({
              success: null,
              error: res.data.message,
            });
          }
        })
        .catch((error) => {
          setResponse({
            success: null,
            error: "Server Error",
          });
        });
      setLoading(false);
    }
    setTimeout(() => {
      setResponse({
        success: null,
        error: null,
      });
    }, 5000);
  };

  return (
    <>
      <div className="contact_page">
        <div className="contact_heading">
          <h3>Contact us</h3>
        </div>
        <div className="contact_map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14132.058636846925!2d85.3450849!3d27.6859418!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6064967133397f28!2sCreatu%20Developers!5e0!3m2!1sen!2snp!4v1651658762195!5m2!1sen!2snp"
            width="100%"
            height="400"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="contact_info container">
          <div className="row">
            <div className="col-lg-4 col-md-4">
              {contactLoading ? (
                <>
                  <h5>contact us</h5>

                  <Stack spacing={3}>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />

                    <Skeleton variant="text" />

                    <Skeleton variant="text" />

                    <Skeleton variant="text" />
                  </Stack>
                </>
              ) : (
                <>
                  <ul className="basic_info">
                    <li className="info_heading">
                      <h5>contact us</h5>
                    </li>
                    <li className="info">
                      <i class="fa-solid fa-phone-flip"></i>
                      <div className="nums">
                        <h5>{contact[0]?.phone}</h5>
                      </div>
                    </li>
                    <li className="info">
                      <i class="fa-solid fa-envelope"></i>
                      <div className="nums">
                        <h5>{contact[0]?.email}</h5>
                      </div>
                    </li>
                    <li className="info">
                      <i class="fa-solid fa-location-dot"></i>
                      <div className="nums">
                        <h5>{contact[0]?.address}</h5>
                      </div>
                    </li>
                    <div className="social_icon_contact">
                      <Link to="#">
                        {" "}
                        <i class="fa-brands fa-youtube"></i>
                      </Link>
                      <Link to="#">
                        {" "}
                        <i class="fa-brands fa-twitter"></i>
                      </Link>
                      <Link to="#">
                        {" "}
                        <i class="fa-brands fa-facebook-f"></i>
                      </Link>
                      <Link to="#">
                        {" "}
                        <i class="fa-brands fa-instagram"></i>
                      </Link>
                    </div>
                  </ul>
                </>
              )}
            </div>

            <div className="col-lg-8 col-md-8">
              <div className="basic_info">
                <form>
                  {response.error ? (
                    <>
                      <Alert severity="error">{response.error}</Alert>
                    </>
                  ) : null}
                  {response.success ? (
                    <>
                      <Alert severity="success">{response.success}</Alert>
                    </>
                  ) : null}

                  <div className="">
                    <label htmlFor="exampleInputName" className="form-label">
                      Full Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      style={
                        validationMessage.name
                          ? {
                            border: "1px solid red",
                          }
                          : null
                      }
                    />
                  </div>
                  {validationMessage.name ? (
                    <span className="error error-message">
                      {validationMessage.name}
                    </span>
                  ) : null}

                  <div className="mt-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={
                        validationMessage.email
                          ? {
                            border: "1px solid red",
                          }
                          : null
                      }
                    />
                  </div>
                  {validationMessage.email ? (
                    <span className="error error-message">
                      {validationMessage.email}
                    </span>
                  ) : null}

                  <div className="mb-3">
                    <div class="mt-3">
                      <label
                        for="exampleFormControlTextarea1"
                        class="form-label"
                      >
                        Message
                      </label>
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="5"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        style={
                          validationMessage.message
                            ? {
                              border: "1px solid red",
                            }
                            : null
                        }
                      />
                    </div>
                    {validationMessage.message ? (
                    <span className="error error-message">
                      {validationMessage.message}
                    </span>
                  ) : null}

                  </div>

                  <div className="button btn-submit">
                    <LoadingButton
                      className="btn"
                      type="submit"
                      loading={loading}
                      variant="outlined"
                      onClick={postSubmit}
                    >
                      Submit
                    </LoadingButton>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
