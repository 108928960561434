import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import ScrollToTop from "./ScrollToTop";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { rootreducer } from "./Services/Reducer/index";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import ThunkMiddleware from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootreducer);
const store = createStore(persistedReducer, applyMiddleware(ThunkMiddleware));

const persistor = persistStore(store);

const PUBLIC_KEY =
  "pk_live_51MHuD9EgRAxgyRVsbBlTXpZlA5OwFhzBMTJH41HSS62PS2Zksxl6BElIZyE9maj8kE5PitjMcuVDnjzm8c0TMjYi00s2xHqPil";
const stripeTestPromise = loadStripe(PUBLIC_KEY);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ScrollToTop />
          <Elements stripe={stripeTestPromise}>
            <App />
          </Elements>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
