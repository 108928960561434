import {
  CardElement,
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import React, { useState } from "react";
import { useSnackbar } from "notistack";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#000",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "black" },
    },
  },
};

export default function StripePaymentForm({ order }) {
  const [success, setSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const cardElement = elements.getElement(CardElement);

    const { error, token } = await stripe.createToken(cardElement);

    if (!error) {
      try {
        console.log("Payment", token);
        order(e, token?.id);
      } catch (err) {
        console.log("Error", error.message);
      }
    } else {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  return (
    <>
      {!success ? (
        <form onSubmit={handleSubmit} className="stripe-form">
          <fieldset className="FormGroup">
            <div className="FormRow">
              <CardElement options={CARD_OPTIONS} />
            </div>
          </fieldset>
          <button>Pay</button>
        </form>
      ) : (
        <></>
      )}
    </>
  );
}
